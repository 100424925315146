import { useEffect, useState } from "react"
import * as Cesium from "cesium";
import useViewer from "./useViewer"
import useContacts from "./useContacts"
import useUrlParams from "./useUrlParams";
// Define interfaces for shared nested properties
export interface GroundStationProperties {
    altitude: string;
    country: string;
    freqbands: string[];
    latitude: string;
    location: string;
    longitude: string;
    provider: string;
    rxFreq: object;
    txFreq: object;
}

export interface LinkInfo {
    bandName: string;
    transceiver: string;
    bandId: string;
    acpBandUid: string;
    mqttTopic: string;
}

export interface BandInfo {
    bandName: string;
    rxFreq: any;
    txFreq: any;
}

export interface ContactStation {
    uniqueId: string;
    tenantId: string;
    contactGspId: string;
    satelliteId: string;
    gspSatId: string;
    startTime: number;
    endTime: number;
    duration: number;
    status: string;
    scenarioId: string;
    groundStationId: string;
    groundStationName: string;
    groundStationProperties: GroundStationProperties;
    gspId: string;
    groundStationProviderName: string;
    uplink: LinkInfo;
    downlink: LinkInfo;
    bandInfo: BandInfo;
    uplinkConfigured: boolean;
    downlinkConfigured: boolean;
};


export type GroundContact = {
    "status": string,
    "message": string,
    "groundStations": ContactStation[]
}

interface Pass {
    start: number;
    end: number;
    gsId: string;
}



const useGroundContact = () => {
    const { viewer } = useViewer()
    const { timelineContactData, planingContactData, selectedContact, setSelectedContact, contacts, setContacts } = useContacts()

    const [loading, setLoading] = useState(true)

    const getContactStatus = (contacts: ContactStation[], currentTime: number) => {
        let ongoingContact: ContactStation[] = [];
        let upcomingContact: ContactStation[] = [];
        let latestPreviousContact: ContactStation[] = [];

        for (const pass of contacts) {
            if (currentTime >= pass?.['startTime'] && currentTime <= pass?.['endTime']) {
                ongoingContact.push(pass)
            } else if (currentTime < pass?.['startTime']) {
                upcomingContact.push(pass);
            } else if (currentTime > pass?.['endTime']) {
                latestPreviousContact.push(pass);
            }
        }
        return {
            ongoing_contacts: ongoingContact?.length > 0 ? ongoingContact : null,
            upcoming_contacts: upcomingContact?.length > 0 ? upcomingContact : null,
            previous_contacts: latestPreviousContact?.length > 0 ? latestPreviousContact : null,
        }
    }


    const get_current_contact_details = (current_time: number) => {
        const { ongoing_contacts, upcoming_contacts, previous_contacts } = getContactStatus(timelineContactData, current_time);
        ongoing_contacts?.sort((a, b) => a.startTime - b.startTime);
        upcoming_contacts?.sort((a, b) => a.startTime - b.startTime);
        previous_contacts?.sort((a, b) => b.startTime - a.startTime);

        const selected_contact: {
            selection_type: 'manual' | 'auto';
            contact_type: string;
            contact_data: ContactStation,
        } | null = ongoing_contacts
                ? { selection_type: 'auto', contact_type: 'Ongoing', contact_data: ongoing_contacts[0] }
                : upcoming_contacts
                    ? { selection_type: 'auto', contact_type: 'Upcoming', contact_data: upcoming_contacts[0] }
                    : previous_contacts
                        ? { selection_type: 'auto', contact_type: 'Previous', contact_data: previous_contacts[0] }
                        : null;

        setContacts({
            previous_contacts,
            ongoing_contacts,
            upcoming_contacts,
            current_time: current_time,
        })

        if (selected_contact && (!selectedContact || selectedContact.selection_type === 'auto' || (selectedContact.selection_type === 'manual' && selectedContact?.contact_data?.uniqueId === selected_contact?.contact_data?.uniqueId))) {
            setSelectedContact(selected_contact)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (timelineContactData?.length > 0 && viewer) {
            let Interval = setInterval(() => {
                const clock = viewer?.clock;
                if (clock) {
                    const cesium_time = clock.currentTime;
                    if (cesium_time) {
                        const current_time = Cesium.JulianDate.toDate(cesium_time).getTime() / 1000;
                        get_current_contact_details(current_time)
                    }
                }
            }, 1000);
            return () => clearInterval(Interval);
        }
    }, [timelineContactData, selectedContact, contacts, viewer])


    return {
        loading,
        contacts,
        planingContactData,
        timelineContactData,
        selectedContact,
        setSelectedContact,
    }
}

export default useGroundContact