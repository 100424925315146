import { Fragment, useState, useEffect, useMemo } from "react";
import './Panel.css'

interface Props {
    panels: any;
    monitor_view_type: string;
    get_updated_url: (url: string) => void
}

const ADCS: React.FC<Props> = ({ panels, monitor_view_type, get_updated_url }) => {


    const [adcType, setadcType] = useState('');
    const [adcTypes, setadcTypes] = useState<string[]>([]);

    useEffect(() => {
        if (panels && Object.keys(panels)?.length > 0) {
            let types: string[] = []
            let selected_type: string = ''
            Object.keys(panels).map((type) => {
                types.push(type)
                selected_type = selected_type === '' ? type : selected_type
            })
            setadcType(selected_type)
            setadcTypes(types)
        }
    }, [panels])

    useEffect(() => {
        window.addEventListener("blur", function (e) {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
    }, [])

    const buttons = useMemo(() => (
        adcTypes.map((btn: string) => (
            <button
                className={`button ${adcType === btn ? 'active' : ''}`}
                onClick={() => {
                    if (adcType !== btn) {
                        setadcType(btn);
                    }
                }}
                key={btn}
            >
                {btn}
            </button>
        ))
    ), [adcType, adcTypes]);
    return (
        <Fragment>
            <div className="d-flex monitor__container__body__header">
                <div className='buttonwrapper'>
                    {buttons}
                </div>
            </div>

            <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>

                {adcType === 'State' && panels[adcType]?.length > 0 && (
                    panels[adcType]?.map((panel: { panel_name: string; url: string }) => (
                        <div className='monitor__item__container' key={panel.panel_name}>
                            {panel.url && (
                                <iframe src={`${get_updated_url(panel.url)}`} className='panel__frame' />
                            )}
                        </div>
                    ))
                )}

                {/* // Note: Power Panels available on flatsat mode */}
                {adcType === 'Power' && panels[adcType]?.length > 0 && (
                    panels[adcType]?.map((panel: { panel_name: string; url: string }) => (
                        <div className='monitor__item__container' key={panel.panel_name}>
                            {panel.url && (
                                <iframe src={`${get_updated_url(panel.url)}`} className='panel__frame' />
                            )}
                        </div>
                    ))
                )}

                {adcType === 'Measurements' && panels[adcType] && Object.keys(panels[adcType])?.map((panel) => (
                    <div className={`monitor__item__container ${adcType === 'Measurements' && panels[adcType][panel]?.length <= 0 ? 'bg-transparent' : ''}`} key={panel}>
                        {panels[adcType][panel]?.length > 0 && (
                            <iframe
                                src={`${get_updated_url(panels[adcType][panel][0]['url'])}`}
                                className='panel__frame'
                            />
                        )}
                    </div>
                ))}
            </div>
        </Fragment >
    )
}

export default ADCS