//@ts-nocheck

import { useSelector } from 'react-redux';
import { AddIcon, NormalSatellite } from '../../../commonMMSvgs/MissionModellingSvgs';
import './satelliteCreation.css'
import SatelliteCreationTree from './SatelliteCreationTree';
import { useEffect, useState } from 'react';
import { gs_visualization_presentation } from '../GroundStationCreation/Utils';

const SatelliteCreation = ({ all_satellites, openSatelliteCreationModal, openedCreationModal, mm_viewer }) => {
    const constellations = useSelector(state => state.missionModelling.constellations)
    const allOrbitData = useSelector(state => state.missionModelling.allOrbit)
    const [constellationCount, setConstellationcount] = useState(0)

    useEffect(() => {
        if (mm_viewer && allOrbitData) {
            gs_visualization_presentation().init_all_satellites(allOrbitData, constellations, mm_viewer, "Orbit_Object")
        }
    }, [allOrbitData, mm_viewer])

    useEffect(() => {
        let satCount = 0;
        constellations?.map((constellation, index) => {
            satCount += parseFloat(constellation?.groups[0]?.satellites?.length)
        })
        setConstellationcount(satCount)
    }, [constellations])

    return (
        <>
            <div className='satelliteCreationHeader'>
                <div className='creationState'>
                    <NormalSatellite />
                    <span className='headerText'>
                        Satellites
                    </span>
                    <span className='headerSubText'>
                        {`(${constellationCount})`}
                    </span>
                </div>
                <button disabled={all_satellites.counts === 125} className='addIcon bg-transparent'
                    onClick={() => { openSatelliteCreationModal() }}>
                    <AddIcon />
                </button>
            </div>
            <div className='satelliteCreationBody'>
                {constellations?.length === 0 && <div className='create_satellite_alignment' onClick={() => { openSatelliteCreationModal() }}>
                    <AddIcon />
                    <span className='bodyText'>Create Satellite</span>
                </div>}

                {constellations?.length > 0 && <SatelliteCreationTree openedCreationModal={openedCreationModal} mm_viewer={mm_viewer} />}
            </div>
        </>
    );
};

export default SatelliteCreation;
