//@ts-nocheck
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeofenceSvg, GroundTargetSvg, VisibleIcon, InvisibleIcon } from '../../../commonMMSvgs/MissionModellingSvgs';
import ModalInput from '../../../commonComponents/ModalInput';
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import { useEffect, useState } from 'react';
import * as mmActions from '../../../../../store/MissionModellingSlice';
import MissionModellingService from '../../../MissionModelling.service';
import { gs_visualization_presentation } from '../GroundStationCreation/Utils';
import Dialog from '../../../../CommonComponents/Dialog';
import './geoFeatureCreation.css'

const GeoFeatureCreationTree = ({ mm_viewer, openedCreationModal }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const {
        mission_name,
        mission_id
    } = params;

    const mode = useSelector(state => state.map.mode);
    const localAoiItem = useSelector(state => state.map.localAoiItem);
    const showLocalAoiItem = useSelector(state => state.map.showLocalAoiItem);
    const selectedGeoFeaturesForCoverage = useSelector(state => state.missionModelling.selectedGeoFeaturesForCoverage)
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);

    const aois = useSelector(state => state.missionModelling.aois);
    const selectedFeature = useSelector(state => state.missionModelling.selectedFeature);
    const aoiCollection = useSelector(state => state.missionModelling.aoiCollection);

    const [selectedAoi, setSelectedAoi] = useState('')
    const [selectedAoiForRename, setSelectedAoiForRename] = useState({
        areaOfInterestId: '',
        name: ''
    })

    const [showAoiDelete, setShowAoiDelete] = useState()

    const [show_menu, set_show_menu] = useState<string | boolean>(false)
    const handleDeleteAOI = (aoi) => {
        setShowAoiDelete(aoi)
    }

    const handleRename = (aoi) => {
        setSelectedAoiForRename({
            areaOfInterestId: aoi.areaOfInterestId,
            name: aoi.name
        })
    }

    const handleAoiClose = () => {
        setSelectedAoiForRename({
            areaOfInterestId: '',
            name: ''
        })
    }

    const handleAoiSave = () => {
        dispatch(MissionModellingService.renameAreaOfInterest(selectedAoiForRename.areaOfInterestId, selectedAoiForRename.name, mission_id))
        setSelectedAoiForRename({
            areaOfInterestId: '',
            name: ''
        })
    }

    const handleEditAoi = (aoi) => {
        dispatch(mmActions.setEditAreaOfInterestObject(aoi))
        dispatch(mmActions.setGeoFeatureView(true));
    }

    const addGeoFeatureForCoverage = (geoFeature) => {
        if (openedCreationModal.coverageAnalysisModal && !creatingConstellation) {
            dispatch(mmActions.setSelectedGeoFeatureForCoverage(geoFeature))
        }
    }

    const isItemActive = (item) => {
        return ((Object.keys(selectedFeature)?.length > 0) &&
            (selectedFeature.properties.areaOfInterestId === item.areaOfInterestId));
    };


    const setClickedItemAsFeature = (item) => {
        const selectedItemId = item.areaOfInterestId;
        let selectedFeatureData = [];
        selectedFeatureData = aoiCollection.features.filter((feature) => {
            return feature.properties['areaOfInterestId'] === selectedItemId;
        });
        if (selectedFeatureData?.length > 0) {
            dispatch(mmActions.setSelectedFeature(selectedFeatureData[0]))
        };
    };

    const get_selected_aoi_list = (aois) => {
        const list = aois.map(aoi => aoi?.['areaOfInterestId'])
        return list
    }

    const handleRemoveGeoFeature = (geoFeature) => {
        dispatch(mmActions.removeSelectedGeoFeatureForCoverage(geoFeature))
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.aoiList')) {
                setSelectedAoi('');
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className='aoiList'>
                {
                    aois && aois?.map((item, index) => (
                        <div
                            key={index}
                            className={`aoi-item ${isItemActive(item) ? 'active' : ''}`}
                            onClick={() => {
                                setClickedItemAsFeature(item)
                                addGeoFeatureForCoverage(item)
                                const selected_all_aoi = get_selected_aoi_list(selectedGeoFeaturesForCoverage)
                                const selected_aoi = selected_all_aoi?.length > 0 ? [...selected_all_aoi, item?.['areaOfInterestId']] : [item?.['areaOfInterestId']]
                                if (item?.type === 'Geofence') {
                                    gs_visualization_presentation().selected_aois(selected_aoi, mm_viewer)
                                } else {
                                    gs_visualization_presentation().selected_target(selected_aoi, mm_viewer)
                                }
                            }}
                            onDoubleClick={() => handleRename(item)}
                            onMouseEnter={() => {
                                set_show_menu(item.areaOfInterestId)
                            }}
                            onMouseLeave={() => {
                                setSelectedAoi('')
                                set_show_menu(false)
                            }}
                        >
                            <div className='geofense_aoi_alignment_state editable-field'>
                                <span className='aoi-icon' style={{ opacity: item?.isVisible ? '1' : '0.4' }}>
                                    {item.type === 'Geofence' ? <GeofenceSvg /> : <GroundTargetSvg />}
                                </span>
                                {selectedAoiForRename.areaOfInterestId === item?.areaOfInterestId ?
                                    <ModalInput
                                        isRename={true}
                                        value={selectedAoiForRename.name}
                                        handleChange={(value) => {
                                            setSelectedAoiForRename({
                                                ...selectedAoiForRename,
                                                name: value
                                            })
                                        }}
                                        handleSave={() => { handleAoiSave() }}
                                        handleClose={() => { handleAoiClose() }}
                                    />
                                    : <span className='gefofense__aoi__name' style={{ opacity: item?.isVisible ? '1' : '0.4' }}>{selectedAoiForRename.areaOfInterestId !== item?.areaOfInterestId && item.name}</span>
                                }

                            </div>
                            <div style={{ display: 'flex', gap: '4px' }}>
                                {show_menu === item?.areaOfInterestId &&
                                    <div onClick={() => {
                                        dispatch(mmActions?.setAoiVisibility(item?.areaOfInterestId));
                                    }
                                    }>
                                        {item?.isVisible &&
                                            <VisibleIcon style={{ marginBottom: '5px' }} />
                                        }
                                        {!item?.isVisible &&
                                            <InvisibleIcon style={{ marginBottom: '5px' }} />
                                        }
                                    </div>
                                }
                                <ActionsMenu
                                    show_menu={show_menu === item?.areaOfInterestId ? true : false}
                                    component='Area of interest'
                                    handleDelete={() => { handleDeleteAOI(item) }}
                                    handleRename={() => { handleRename(item) }}
                                    handleEdit={() => { handleEditAoi(item) }}
                                />
                            </div>
                        </div>
                    ))
                }
                {showLocalAoiItem &&
                    (<div className='aoi-item active'>
                        <div className='geofense_aoi_alignment_state editable-field'>
                            <span className='aoi-icon'>
                                {mode === 'draw_polygon' ? <GeofenceSvg /> : <GroundTargetSvg />}
                            </span>
                            <span className='gefofense__aoi__name'>{localAoiItem}</span>
                        </div>
                    </div>
                    )
                }
            </div >
            <Dialog
                onClick={(event) => {
                    if (event) {
                        dispatch(MissionModellingService.deleteAreaOfInterest(showAoiDelete.areaOfInterestId, mission_id))
                        handleRemoveGeoFeature(showAoiDelete)
                        gs_visualization_presentation().remove_entity_by_id(showAoiDelete.areaOfInterestId, 'AOI_Object', mm_viewer)
                        setShowAoiDelete()
                    }
                    setShowAoiDelete()
                }}
                openModel={showAoiDelete}
                comment={`Do you want to delete ${showAoiDelete?.name}?`}
            />
        </>
    );
};

export default GeoFeatureCreationTree;
