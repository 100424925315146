import { useSelector } from "react-redux";
import "./modalFooter.css"
import { CircularProgress } from "@mui/material";

interface ModalFooterProps { isValid: boolean, actionText: string, loadingText: string, closingText: string, handleSubmit: () => void, handleClose: () => void, modal: string, loader: boolean, isUpdateEnabled: boolean }

const ModalFooter = ({ isValid, actionText, loadingText, closingText, handleSubmit, handleClose, modal, loader, isUpdateEnabled }: ModalFooterProps) => {

    const creatingConstellation = useSelector((state: { missionModelling: { creatingConstellation: boolean } }) => state.missionModelling.creatingConstellation)
    const actionClass =
        (actionText === "Update " || isUpdateEnabled || actionText === "Add") ? `modalFooterapply-update ${isUpdateEnabled ? "enable-btn" : ""}`
            : actionText === "Create " ? "modalFooterapply-create" : "";
    return (
        <div className="modalFooter-edit">
            <button
                disabled={loader}
                className={`modalFooterclose-edit ${(!loader) ? '' : 'disable_analysis_button-edit'}`}
                onClick={() => { handleClose() }}
            >
                {closingText}
            </button>
            <button
                disabled={loader}
                className={`modalFooterapply-edit ${actionClass} ${(isValid && !loader) ? '' : 'disable_analysis_button-edit'}`}
                onClick={() => { isValid && handleSubmit() }}
            >
                {loader ? loadingText : actionText}
                {loader && < CircularProgress size={10} className="text-white" />}
            </button>
        </div>
    )
};

export default ModalFooter;