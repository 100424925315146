import { TaskCompleteSvg, TaskNotPerformedSvg, TaskOngoingSvg, TaskUpcommingSvg } from "./components/center/modals/Svgs";

export const get_formated_time = (time: number) => {
    const date = new Date(time * 1000);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T ${hours}:${minutes}:${seconds}Z`;
}

export function calculate_duration(start: number, end: number) {
    // Calculate the difference in seconds
    const durationInSeconds = end - start;

    // Calculate minutes and seconds
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;

    // Format the result as "Xm Ys"
    return `${minutes}m ${seconds}s`;
}

export const get_task_status_icon = (start_time: number, end_time: number, current_time: number) => {
    if (start_time <= current_time && end_time >= current_time) {
        return <TaskOngoingSvg />
    } else if (current_time < start_time) {
        return <TaskUpcommingSvg />
    } else if (current_time > end_time) {
        return <TaskCompleteSvg />
    } else {
        return <TaskNotPerformedSvg />
    }
}

export const get_time_only = (time: number) => {
    const timestamp = new Date(time).toUTCString();
    const match = timestamp.match(/\b\d{2}:\d{2}:\d{2}\b/); // Look for the time in HH:MM:SS format
    if (match) {
        return match[0];
    } else {
        return '00:00:00';
    }
}