// @ts-nocheck
import React from "react";
import "./geoFeatureCreation.css";
import { Draw_Polygon, Draw_Point } from "../../../commonMMSvgs/MissionModellingSvgs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AoiConfigPopup from "./AoiConfigPopup/AoiConfigPopup";
import * as mapActions from "../../../../../store/MapSlice";
import * as mmActions from "../../../../../store/MissionModellingSlice";

const GeoFeatureOptions = () => {

    const dispatch = useDispatch();

    const mode = useSelector(state => state.map.mode);
    const showAoiConfigPopup = useSelector(state => state.map.showAoiConfigPopup);

    const aois = useSelector(state => state.missionModelling.aois);
    const selectedFeature = useSelector(state => state.missionModelling.selectedFeature);
    const editAreaOfInterestObject = useSelector(state => state.missionModelling.editAreaOfInterestObject);

    const cleanUpDrawnFeatures = () => {
        dispatch(mapActions.resetLocalAoiItem());
        dispatch(mapActions.resetDrawnFeatureInfo());
        dispatch(mapActions.cleanUpDraw());
    };

    const drawFeatures = (state) => {
        dispatch(mapActions.drawAOI(state.map.mode));
    };

    const selectAction = (action) => {
        if (Object.keys(selectedFeature).length > 0) dispatch(mmActions.resetSelectedFeature());
        if (showAoiConfigPopup) dispatch(mapActions.resetAoiConfigPopup());
        if (action === 'simple_select') {
            dispatch(mapActions.selectMode(action, cleanUpDrawnFeatures));
        } else {
            dispatch(mapActions.selectMode(action, drawFeatures));
            const local_aoi_name = `Area of Interest ${aois?.length + 1}`;
            dispatch(mapActions.setLocalAoiItem(local_aoi_name));
        }
    };

    const exitGeoFeature = () => {
        dispatch(mmActions.resetGeoFeatureView());
        dispatch(mapActions.resetMode());
        cleanUpDrawnFeatures();
    };

    return (
        <>
            <div className="geoFeatureOptions">
                <div className="feature-option">
                    {(Object.keys(editAreaOfInterestObject).length === 0 || (Object.keys(editAreaOfInterestObject).length > 0 && editAreaOfInterestObject.type === 'Geofence')) &&
                        <button
                            className={`draw-icon ${mode === 'draw_polygon' ? 'active-action' : ''}`}
                            onClick={() => selectAction('draw_polygon')}
                        >
                            <Draw_Polygon />
                        </button>
                    }
                    {(Object.keys(editAreaOfInterestObject).length === 0 || (Object.keys(editAreaOfInterestObject).length > 0 && editAreaOfInterestObject.type === 'Target Track')) &&
                        <button
                            className={`draw-icon ${mode === 'draw_point' ? 'active-action' : ''}`}
                            onClick={() => selectAction('draw_point')}
                        >
                            <Draw_Point />
                        </button>
                    }
                </div>
                <div
                    className="feature-option"
                    onClick={() => exitGeoFeature()}
                >
                    Exit
                </div>
            </div>
            {showAoiConfigPopup && <AoiConfigPopup />}
        </>
    );
};

export default GeoFeatureOptions;
