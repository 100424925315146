import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import { v4 as uuidv4 } from "uuid";

import CommandCenterService from '../../../../../../service/CommandCenterService';
import useUrlParams from '../../../../hooks/useUrlParams';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import useScenario from '../../../../hooks/useScenario';
import './Console.scss'
import { getEnvVar } from '../../../../../../envUtils';
import { get_formated_time } from '../../../../utils';



const fetchEntries = async (sat_id, truetwin_id, scenario_id, cursor) => {
    let response_data = {
        data: [],
        cursor: '',
    };
    try {
        const response = await CommandCenterService.getAllCommandDetails(
            sat_id,
            truetwin_id,
            scenario_id,
            cursor,
            50
        );
        // Extract cursorId from the first entry in the response
        console.log(response.data);
        
        const cursorId = response.data?.[0]?.['cursorId'] || '';
        const sorted_data = response.data ? response.data : []
        response_data = {
            data: sorted_data,
            cursor: cursorId,
        };
    } catch (error) {
        console.log(error);
    }

    return response_data;
};

function ConsoleOuputList({ height, latestDatacount, getCommandDetails, show_selected_output_details, selectedRow }) {
    const { sat_id, truetwin_id, scenario_id } = useUrlParams(); // Get parameters from the custom hook
    const { scenario } = useScenario()

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null);
    const cursorRef = useRef('')
    const scrllRef = useRef(false)


    const truncate_rows = (index) => {
        const commandIndexs = selectedRow.commandIndexs
        if (commandIndexs.length > 1) {
            if (index === commandIndexs[0] || index === commandIndexs[commandIndexs.length - 1]) {
                return `${commandIndexs[0] === index ? 'request' : 'response'}`
            } else if (index > commandIndexs[0] && index < commandIndexs[commandIndexs.length - 1]) {
                return 'truncate__row'
            }
        }
        return ''
    }
    const loadMoreEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id || !scrllRef.current) return; // Ensure parameters are available
        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, cursorRef.current, 50);
        if (data.length && newCursor) {
            setEntries((prev) => [...prev, ...data].sort((a, b) => b.CommandDetails.groundTimestamp - a.CommandDetails.groundTimestamp));
            cursorRef.current = newCursor // Update the cursor with the new one from the API
        }
        setLoading(false);
    };

    const loadLatestEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id || scrllRef.current) return; // Ensure parameters are available

        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, '', 50); // Reset the cursor to fetch the latest
        setEntries(data);  // Replace the current entries with the latest ones
        cursorRef.current = newCursor // Update the cursor with the new one from the API
        if (listRef.current) {
            listRef.current.scrollTo(0, 0); // Scroll to top
        }
        setLoading(false);
    };

    useEffect(() => {
        loadLatestEntries(); // Load initial entries
    }, [sat_id, truetwin_id, scenario_id]);


    // useEffect(() => {
    //     const clearFormFields = () => {
    //         loadLatestEntries();
    //     };

    //     window.addEventListener('loadCurrentData', clearFormFields);
    //     return () => {
    //         window.removeEventListener('loadCurrentData', clearFormFields);
    //     };
    // }, []);



    const handleScroll = useCallback(({ scrollOffset, scrollDirection }) => {
        if (scrollDirection === 'forward' && !loading) {
            const listElement = listRef.current;
            if (listElement) {
                scrllRef.current = true
                const pixelBuffer = Math.ceil(window.devicePixelRatio); // Adjust buffer dynamically
                const bottomReached =
                    scrollOffset + listElement.props.height + pixelBuffer >=
                    listElement.props.itemSize * entries.length;

                // Ensure cursor is valid before loading more entries
                if (bottomReached && cursorRef.current) {
                    loadMoreEntries();
                }
            }
        } else if (scrollDirection === 'backward' && scrollOffset === 0) { //load latest entries when scrollbar is at top
            scrllRef.current = false
            loadLatestEntries();
        }
    }, []);


    //web socket start//
    const create_socket = (sessionId) => {
        try {
            return new WebSocket(`${getEnvVar('REACT_APP_WEBSOCKET_BACKEND_BASE_URL')}groundstation/websocketstatus?truetwinId=${truetwin_id}&sessionId=${sessionId}`);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        const token = sessionStorage.getItem("authentication");
        if (scenario?.['trueTwinId'] && token) {
            const sessionId = uuidv4();
            let ws = create_socket(sessionId)
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'authorization', token: `${token}` }));
                console.log('WebSocket Connected');
            };
            ws.onmessage = (event) => {
                loadLatestEntries()
            };

            ws.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
                let ws = create_socket(sessionId)
            };
            return () => {
                ws.close();
            };
        }
    }, [scenario]);
    //web socket end//

    const Row = useCallback(({ index, style }) => {
        const entry = entries[index];
        const commandIndexs = selectedRow.commandIndexs;
        const isSelected = selectedRow.selectedRow === index;
        const isHidden =
            commandIndexs.length > 1 &&
            index > commandIndexs[0] + 4 &&
            index < commandIndexs[commandIndexs.length - 1];

        // Function to determine the class based on tctmType
        const getTctmTypeClass = (tctmType) => {
            switch (tctmType) {
                case 'Beacon': // Change 'type1' to the actual tctmType value
                    return 'bg-typeBeacon';
                case 'Health Metric': // Change 'type2' to the actual tctmType value
                    return 'bg-typeHealthMetric';
                case 'TCTM': // Change 'type3' to the actual tctmType value
                    return 'bg-typeTCTM';
                case 'TC': // Change 'type4' to the actual tctmType value
                    return 'bg-typeTC';
                case 'TM': // Change 'type5' to the actual tctmType value
                    return 'bg-typeTM';
                case "Invalid TM":
                    return 'bg-invalidTM'
                default:
                    return '';
            }
        };

        const rowClass = `table__body__row ${isHidden ? 'd-none' : 'd-flex'} ${getTctmTypeClass(entry?.CommandDetails?.tctmType)}`;
        const buttonClass = `table__body__row__item ${truncate_rows(index)} ${isSelected ? 'selected' : ''}`;
        return (
            <div key={entry?.id} style={style}>
                <div className={rowClass} title={entry?.CommandDetails?.tctmType} >
                    <button
                        onClick={() => {
                            getCommandDetails(entry);
                            show_selected_output_details(entry, entries);
                        }}
                        className={buttonClass}
                        style={{
                            borderLeft:
                                entry?.type === "Tc" && entry?.CommandDetails?.tctmType === "TCTM"
                                    ? "2px solid #CCF54E"
                                    : entry?.type === "Tm" && entry?.CommandDetails?.tctmType === "TCTM"
                                        ? "2px solid #36F097"
                                        : undefined,
                        }}
                    >
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '90px' }}>
                            <span className='truncate__text uppercase'>
                                <CustomToolTip title={entry?.type} placement='top'>
                                    {entry?.CommandDetails?.tctmType === "Health Metric" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                            <path d="M12.6667 2.5H3.33333C2.59695 2.5 2 3.09695 2 3.83333V13.1667C2 13.903 2.59695 14.5 3.33333 14.5H12.6667C13.403 14.5 14 13.903 14 13.1667V3.83333C14 3.09695 13.403 2.5 12.6667 2.5Z" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.3332 8.49984H9.99984L8.6665 11.8332L7.33317 5.1665L5.99984 8.49984H4.6665" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    {entry?.CommandDetails?.tctmType === "Beacon" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                            <path d="M3.75058 12.749C1.41647 10.4149 1.41647 6.5846 3.75058 4.25049" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.48601 11.0134C4.10948 9.6369 4.10948 7.36264 5.48601 5.92627" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.99971 9.6962C8.66079 9.6962 9.19669 9.1603 9.19669 8.49922C9.19669 7.83815 8.66079 7.30225 7.99971 7.30225C7.33864 7.30225 6.80273 7.83815 6.80273 8.49922C6.80273 9.1603 7.33864 9.6962 7.99971 9.6962Z" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.5137 5.98584C11.8902 7.36236 11.8902 9.63662 10.5137 11.073" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2495 4.25049C14.5836 6.5846 14.5836 10.3551 12.2495 12.6892" stroke="#7AB3FF" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    {entry?.CommandDetails?.tctmType === "Invalid TM" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                            <path d="M12.8594 11.4965L8.86259 4.50203C8.77544 4.34825 8.64906 4.22035 8.49634 4.13136C8.34363 4.04237 8.17004 3.99548 7.99328 3.99548C7.81653 3.99548 7.64294 4.04237 7.49022 4.13136C7.3375 4.22035 7.21112 4.34825 7.12398 4.50203L3.12716 11.4965C3.03907 11.649 2.99288 11.8221 2.99327 11.9983C2.99366 12.1745 3.04062 12.3474 3.12938 12.4996C3.21815 12.6517 3.34556 12.7777 3.49871 12.8648C3.65187 12.9518 3.82531 12.9968 4.00147 12.9953H11.9951C12.1704 12.9951 12.3426 12.9488 12.4943 12.861C12.6461 12.7732 12.7721 12.6471 12.8597 12.4952C12.9472 12.3433 12.9933 12.1711 12.9933 11.9958C12.9932 11.8205 12.9471 11.6483 12.8594 11.4965Z" stroke="#C91818" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.9984 7V8.99841" stroke="#C91818" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.9984 10.9968H8.00339" stroke="#C91818" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    &nbsp;
                                    {entry?.type}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '160px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={get_formated_time(entry?.CommandDetails?.timestamp)} placement='top'>
                                    {get_formated_time(entry?.CommandDetails?.timestamp)}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '160px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.['groundTimestamp'] ? get_formated_time(entry?.CommandDetails?.['groundTimestamp'] / 1000) : '--'} placement='top'>
                                    {entry?.CommandDetails?.['groundTimestamp'] ? get_formated_time(entry?.CommandDetails?.['groundTimestamp'] / 1000) : '--'}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start position-relative' style={{ width: '80px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.saId} placement='top'>
                                    {entry?.CommandDetails?.saId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '100px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.daId} placement='top'>
                                    {entry?.CommandDetails?.daId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '100px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.frameLength} placement='top'>
                                    {entry?.CommandDetails?.frameLength}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '100px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.tctmId} placement='top'>
                                    {entry?.CommandDetails?.tctmId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '140px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.['Info']} placement='top'>
                                    {entry?.['Info'] ? entry?.['Info'] : '--'}
                                </CustomToolTip>
                            </span>
                        </div>
                    </button>
                </div>
            </div >
        );
    }, [entries, selectedRow]);

    return (
        <div>
            <List
                ref={listRef}
                height={height}
                itemCount={entries?.length}
                itemSize={35} // Adjusted height for posts
                width={'100%'}
                onScroll={handleScroll}
            >
                {Row}
            </List>
        </div>
    );
}

export default ConsoleOuputList;
