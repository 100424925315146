//@ts-nocheck
import { Form } from 'react-bootstrap';
import ModalInput from '../../../commonComponents/ModalInput';
import './groundStationCreationModal.css'
import { useEffect, useState } from 'react';
import ModalHeader from '../../../commonComponents/ModalHeader';
import ModalFooter from '../../../commonComponents/ModalFooter';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderGroundStationIcon } from '../../../commonMMSvgs/MissionModellingSvgs';
import { gs_visualization_presentation } from './Utils';
import GroundStationService from '../../../../../service/GroundStationService';

const GroundStationCreationModal = ({ closeModalCreation, mm_viewer }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const {
        mission_name,
        mission_id
    } = params;

    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const editGroundStationData = useSelector(state => state.missionModelling.editGroundStationObject);
    const [openSideBarModal, setOpenSideBarModal] = useState('')
    const [isMinimized, setIsMinimized] = useState(false)
    const [groundStationInput, setGroundStationInput] = useState({
        groundStationName: 'GS_01',
        latitude: '35.5',
        longitude: '35.5',
        minElevation: '37',
        x_band: false,
        s_band: false,
        UHF: false
    })
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);
    const gsCreationLoader = useSelector(state => state.missionModelling.gsCreationLoader);
    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)
    const [isUpdateEnabled, setUpdateEnabled] = useState(false)
    const isValidInputs = () => {
        if (groundStationInput?.groundStationName?.length > 0 &&
            groundStationInput?.latitude?.length >= -90 && groundStationInput?.latitude?.length <= 90 &&
            groundStationInput?.longitude?.length >= -180 && groundStationInput?.longitude?.length <= 180 &&
            groundStationInput?.minElevation.length > 0 &&
            (groundStationInput?.x_band || groundStationInput?.s_band || groundStationInput?.UHF)) {
            return true
        }
        return false
    }

    //Commenting this code as it might need in future
    // useEffect(() => {
    //     if (mm_viewer) {
    //         gs_visualization_presentation().init_default_gs(mm_viewer)
    //     }
    // }, [mm_viewer])

    const removeEntity = () => {
        closeModalCreation()
        gs_visualization_presentation().remove_default_gs(mm_viewer)
    }
    const handleSubmit = () => {
        let bands = []
        if (groundStationInput.x_band) { bands.push('X-band') }
        if (groundStationInput.s_band) { bands.push('S-band') }
        if (groundStationInput.UHF) { bands.push('UHF') }

        let properties = {
            "latitude": groundStationInput.latitude,
            "longitude": groundStationInput.longitude,
            "freqbands": bands,
            "provider": ""
        };

        if (Object.keys(editGroundStationData).length > 0) {
            // Edit Ground Station
            let groundStationBody = {
                ...editGroundStationData,
                properties: { ...editGroundStationData.properties }
            };
            delete groundStationBody.isVisible;
            // Ensure that properties object exists

            groundStationBody.groundStationName = groundStationInput.groundStationName;
            groundStationBody.properties.latitude = groundStationInput.latitude;
            groundStationBody.properties.longitude = groundStationInput.longitude;
            groundStationBody.properties.freqbands = properties.freqbands;

            dispatch(MissionModellingService.editGroundStation(groundStationBody.groundStationId, groundStationBody, closeModalCreation, mission_id))
        } else {
            // Create a new ground station 
            let groundStationBody = {
                "groundStationName": groundStationInput.groundStationName,
                "properties": properties,
                "status": "Configure",
                "missionId": mission_id
            }
            dispatch(MissionModellingService.createGroundStation(groundStationInput.groundStationName, groundStationBody, closeModalCreation, mission_id))
        }
    }

    useEffect(() => {
        if ((Object.keys(editGroundStationData).length > 0)) {
            setGroundStationInput({
                groundStationName: editGroundStationData?.groundStationName,
                latitude: editGroundStationData?.properties?.latitude,
                longitude: editGroundStationData?.properties?.longitude,
                minElevation: '37',
                x_band: editGroundStationData?.properties?.freqbands.includes('X-band'),
                s_band: editGroundStationData?.properties?.freqbands.includes('S-band'),
                UHF: editGroundStationData?.properties?.freqbands.includes('UHF'),
            })
        }
    }, [editGroundStationData])
    return (
        <div className='ground_station_main_modal'>
            <ModalHeader
                icon={<HeaderGroundStationIcon />}
                title={'Create New GS'}
                handleClose={removeEntity}
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
            />
            {!isMinimized && (
                <div className='w-100' style={{ pointerEvents: gsCreationLoader ? 'none' : 'auto', opacity: gsCreationLoader ? '0.5' : '1' }}>
                    <div className='ground_station_creation_modal_body' >
                        <div className='ground_station_creation_item'>
                            <span> Name </span>
                            <div>
                                <ModalInput value={groundStationInput.groundStationName} handleChange={(value) => {
                                    const data = {
                                        ...groundStationInput,
                                        groundStationName: value
                                    }
                                    setGroundStationInput(data)
                                    gs_visualization_presentation().update_default_gs(data, mm_viewer)
                                    setUpdateEnabled(true)
                                }} />
                            </div>
                        </div>
                        <div className='ground_station_creation_item'>
                            <span> Latitude </span>
                            <div>
                                <ModalInput
                                    unit='°'
                                    value={groundStationInput.latitude}
                                    handleChange={(value) => {
                                        const numericValue = parseFloat(value);
                                        if (value === '' || (!isNaN(numericValue) &&
                                            (numericValue >= -90) &&
                                            (numericValue <= 90))) {
                                            const data = {
                                                ...groundStationInput,
                                                latitude: value
                                            }
                                            setGroundStationInput(data)
                                            gs_visualization_presentation().update_default_gs(data, mm_viewer)
                                            setUpdateEnabled(true)
                                        }

                                    }}
                                    inputType='number'
                                    minLength={-90}
                                    maxlength={90}
                                />
                            </div>
                        </div>
                        <div className='ground_station_creation_item'>
                            <span> Longitude </span>
                            <div>
                                <ModalInput
                                    unit='°'
                                    value={groundStationInput.longitude}
                                    handleChange={(value) => {
                                        const numericValue = parseFloat(value);
                                        if (value === '' || (!isNaN(numericValue) &&
                                            (numericValue >= -180) &&
                                            (numericValue <= 180))) {
                                            const data = {
                                                ...groundStationInput,
                                                longitude: value
                                            }
                                            setGroundStationInput(data)
                                            gs_visualization_presentation().update_default_gs(data, mm_viewer)
                                            setUpdateEnabled(true)
                                        }
                                    }}
                                    inputType='number'
                                    minLength={-180}
                                    maxlength={180}
                                />
                            </div>
                        </div>
                        {/* <div className='ground_station_creation_item'>
                            <span> Min. Elevation </span>
                            <div>
                                <ModalInput unit='°' value={groundStationInput.minElevation} handleChange={(value) => {
                                    const data = {
                                        ...groundStationInput,
                                        minElevation: value
                                    }
                                    setGroundStationInput(data)
                                    // gs_visualization_presentation().update_default_gs(data, mm_viewer)
                                }} />
                            </div>
                        </div> */}

                        <div className='comms_panel'>
                            <div className='comms_panel_title'>
                                <span> Communication Bands </span>
                            </div>
                            <div className='comms_panel_band_combination'>
                                <div className='comms_panel_band'>
                                    <Form.Check
                                        type="checkbox"
                                        label=""
                                        checked={groundStationInput.x_band}
                                        onChange={(e) => {
                                            setGroundStationInput({
                                                ...groundStationInput,
                                                x_band: !groundStationInput.x_band
                                            })
                                            setUpdateEnabled(true)
                                        }}
                                    />
                                    <span>X-Band</span>
                                </div>

                                <div className='comms_panel_band'>
                                    <Form.Check
                                        type="checkbox"
                                        label=""
                                        checked={groundStationInput.s_band}
                                        onChange={(e) => {
                                            setGroundStationInput({
                                                ...groundStationInput,
                                                s_band: !groundStationInput.s_band
                                            })
                                            setUpdateEnabled(true)
                                        }}
                                    />
                                    <span>S-Band</span>
                                </div>

                                <div className='comms_panel_band'>
                                    <Form.Check
                                        type="checkbox"
                                        label=""
                                        checked={groundStationInput.UHF}
                                        onChange={(e) => {
                                            setGroundStationInput({
                                                ...groundStationInput,
                                                UHF: !groundStationInput.UHF
                                            })
                                            setUpdateEnabled(true)
                                        }}
                                    />
                                    <span>UHF</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                        isValid={isValidInputs()}
                        actionText={'Add'}
                        loadingText={'Adding...'}
                        closingText='Close'
                        handleClose={removeEntity}
                        handleSubmit={handleSubmit}
                        loader={gsCreationLoader}
                        isUpdateEnabled={isUpdateEnabled}
                    />
                </div>
            )}
        </div>
    );
};

export default GroundStationCreationModal;