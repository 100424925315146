//@ts-nocheck
import { useEffect, useState } from 'react';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import ModalInput from '../../../commonComponents/ModalInput';
import { ConfigSatIcon, ConfigModalInfoIcon } from '../../../commonMMSvgs/MissionModellingSvgs';
import './satelliteConfig.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { toast } from 'react-toastify';
import { hexToRgbaArray } from '../../../../CommonComponents/CommonFunctions';

const SatelliteConfig = ({ closeModalCreation }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const { mission_id, scenario_id } = params;
    const editConstellationConfig = useSelector(state => state.missionModelling.editConstellationConfig)
    const constellationIdForSatConfigEdit = useSelector(state => state.missionModelling.constellationIdForSatConfigEdit)
    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const orbitColors = [
        "#DB3225", "#E91E63", "#AA25C1", "#703BCD",
        "#4357C4", "#2279BE", "#008EA1", "#009688",
        "#3D9941", "#869118", "#9C8E13", "#C19510",
        "#DF8B0E", "#FF5722"
    ];

    const [selectedOrbitColor, setSelectedOrbitColor] = useState("");

    const [swath, setSwath] = useState('')
    const [orientation, setOrientation] = useState('nadir')
    const [isMinimized, setIsMinimized] = useState(false)
    const [constId, setConstId] = useState()
    const [satId, setSatId] = useState()
    const groupMessage = editConstellationConfig?.groups
        ? {
            backgroundColor: 'rgba(242, 169, 59, 0.10)',
            message: 'Satellites in this group share swath values; changing one affects all.',
        }
        : {
            backgroundColor: 'rgba(36, 101, 178, 0.20)',
            message:
                'Changing the swath value detaches the satellite from Group configuration, potentially affecting analysis.',
        };
    const checkIsValid = () => {
        if (swath?.length > o && orientation?.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = () => {
        let isValidSwath = false;
        let maxLimit;
        let swathRange
        if (editConstellationConfig?.groups) {
            swathRange = parseFloat(parseFloat(editConstellationConfig.groups[0].referenceOrbit?.semiMajorAxis) - 6371)
        } else if (editConstellationConfig?.id) {
            swathRange = parseFloat(parseFloat(editConstellationConfig.orbitParameters?.semiMajorAxis) - 6371)
        }
        if (swathRange >= 300 && swathRange < 350) {
            if (swath <= 312.34 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 312.34;
            }
        } else if (swathRange >= 350 && swathRange < 400) {
            if (swath <= 364.40 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 364.40;
            }
        } else if (swathRange >= 400 && swathRange < 450) {
            if (swath <= 416.45 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 416.45;
            }
        } else if (swathRange >= 450 && swathRange < 500) {
            if (swath <= 468.51 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 468.51;
            }
        } else if (swathRange >= 500 && swathRange < 550) {
            if (swath <= 520.57 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 520.57;
            }
        } else if (swathRange >= 550 && swathRange < 600) {
            if (swath <= 572.6 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 572.6;
            }
        } else if (swathRange >= 600 && swathRange < 650) {
            if (swath <= 624.68 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 624.68;
            }
        } else if (swathRange >= 650 && swathRange < 700) {
            if (swath <= 676.74 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 676.74;
            }
        } else if (swathRange >= 700 && swathRange < 750) {
            if (swath <= 728.79 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 728.79;
            }
        } else if (swathRange >= 750 && swathRange < 800) {
            if (swath <= 780.85 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 780.85;
            }
        }


        let satelliteSwath = editConstellationConfig?.groups?.[0]?.satellites?.[0]?.config

        let body = {
            config: {
                swath: swath,
                orientation: orientation,
                orbitColour: selectedOrbitColor,
            }
        }

        if (isValidSwath) {
            dispatch(MissionModellingService.editConstellationConfig(constId, mission_id, scenario_id, body, closeModalCreation, satId))
        } else {
            if (maxLimit) {
                toast.error('Swath Limit shoud be between 5 - ', maxLimit)
            } else {
                toast.error('Swath Exceeds the Max Limit')
            }
        }
    }

    useEffect(() => {
        if (Object.keys(editConstellationConfig).length > 0) {
            if (editConstellationConfig?.groups) {
                setConstId(editConstellationConfig.constellationId)
                if (editConstellationConfig?.isGroupSwathConfigEffective === true) {
                    setSwath(editConstellationConfig?.groups[0]?.satellites[0]?.config?.swath)
                } else if (editConstellationConfig?.isGroupSwathConfigEffective === false) {
                    setSwath("Mixed")
                }
            } else if (editConstellationConfig?.id) {
                setSatId(editConstellationConfig?.id)
                setConstId(constellationIdForSatConfigEdit)
                setSwath(editConstellationConfig?.config?.swath)
            }
            editConstellationConfig?.groups[0]?.satellites[0]?.orbitColour ?
                setSelectedOrbitColor(editConstellationConfig?.groups[0]?.satellites[0]?.orbitColour) :
                setSelectedOrbitColor("#DB3225"); // default red
        }
    }, [editConstellationConfig])

    return (
        <>
            <ModalHeader
                icon={<ConfigSatIcon />}
                title={'Config'}
                handleClose={() => { closeModalCreation() }}
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
            />

            {!isMinimized && (<>
                <div className='satellite_config_item'>
                    <span>Swath</span>
                    <ModalInput
                        unit='km'
                        value={swath}
                        handleChange={(value) => {
                            setSwath(value)
                        }}
                    />
                </div>
                <div className="groupConfigSameCaseMessage" style={{ backgroundColor: groupMessage.backgroundColor }}>
                    <div>
                        <ConfigModalInfoIcon />
                    </div>
                    <span className="configInfo">{groupMessage.message}</span>
                </div>
                {/* <div className='satellite_config_item'>
                    <span>Orientation</span>
                    <ModalInput
                        value={orientation}
                        handleChange={() => { }}
                    />
                </div> */}

                <div className='orbit-color-config'>
                    {orbitColors.map((color) => (
                        <div
                            key={color}
                            onClick={() => setSelectedOrbitColor(color)}
                            className='color-box'
                            style={{
                                backgroundColor: color,
                                border: `2px solid ${selectedOrbitColor === color ? `#CCF54E` : "transparent"
                                    }`,
                                boxShadow: selectedOrbitColor === color ? "0 0 0 2px black" : "none",
                            }}
                        >
                        </div>
                    ))}
                </div>

                <ModalFooter
                    isValid={() => { checkIsValid() }}
                    closingText={'Close'}
                    actionText={'Save'}
                    loadingText={'Saving ...'}
                    handleSubmit={() => { handleSubmit() }}
                    handleClose={() => { closeModalCreation() }}
                    loader={false}
                />
            </>)}
        </>
    );
};

export default SatelliteConfig;
