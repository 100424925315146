// @ts-nocheck
import React, { useState, useEffect } from 'react';
import './AoiConfigPopup.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as mapActions from '../../../../../../store/MapSlice';
import * as mmActions from '../../../../../../store/MissionModellingSlice.js';
import MissionModellingService from '../../../../MissionModelling.service';
import { CloseSvg, SaveSvg } from '../../../../commonMMSvgs/MissionModellingSvgs';

const AoiConfigPopup = () => {

    const params = useParams()
    const dispatch = useDispatch();

    const {
        mission_id
    } = params;

    const mode = useSelector(state => state.map.mode);
    const coordinates = useSelector(state => state.map.coordinates);
    const localAoiItem = useSelector(state => state.map.localAoiItem);
    const promptPosition = useSelector(state => state.map.promptPosition);
    const editAreaOfInterestObject = useSelector(state => state.missionModelling.editAreaOfInterestObject);

    const cleanUpDrawnFeatures = () => {
        dispatch(mapActions.resetMode());
        dispatch(mapActions.resetLocalAoiItem());
        dispatch(mapActions.resetAoiConfigPopup());
        dispatch(mapActions.resetDrawnFeatureInfo());
        dispatch(mapActions.cleanUpDraw());

    };

    const closeFunction = () => {
        cleanUpDrawnFeatures();
        dispatch(mmActions.setGeoFeatureView(true));
    };

    const saveFunction = () => {
        let payload = {};
        let body;

        if (Object.keys(editAreaOfInterestObject).length === 0) {
            if (mode === 'draw_polygon') {
                payload = {
                    "areaOfInterests": [
                        {
                            "name": localAoiItem,
                            "type": 'Geofence',
                            "description": '',
                            "polygon": coordinates[0]
                        },
                    ],
                    "name": "Janus5",
                    "type": "Single"
                }
            } else if (mode === 'draw_point') {
                payload = {
                    "areaOfInterests": [
                        {
                            name: localAoiItem,
                            type: 'Target Track',
                            description: '',
                            coordinates: {
                                altitude: 0,
                                lat: coordinates[1],
                                long: coordinates[0],
                                minElevationAngle: 0,
                            }
                        }
                    ],
                    "name": "Janus5",
                    "type": "Single"
                }
            };
            dispatch(MissionModellingService.saveAois(mission_id, payload));
        } else {
            body = { ...editAreaOfInterestObject }
            if (body.type === 'Geofence') {
                body.polygon = coordinates[0]
                delete body.missionId;
                delete body.tenantId;
                delete body.isVisible;
                dispatch(MissionModellingService.editAreaOfInterest(editAreaOfInterestObject.areaOfInterestId, body, mission_id))
            } else if (body.type === "Target Track") {
                // Creating copy because lat long in body are read only fields
                const coordinatesCopy = { ...body.coordinates };
                coordinatesCopy.lat = coordinates[1];
                coordinatesCopy.long = coordinates[0];
                body.coordinates = coordinatesCopy;
                delete body.missionId;
                delete body.tenantId;
                delete body.isVisible;
                dispatch(MissionModellingService.editAreaOfInterest(editAreaOfInterestObject.areaOfInterestId, body, mission_id))
            }
        }

        cleanUpDrawnFeatures();
        dispatch(mmActions.setGeoFeatureView(false));
    };

    return (
        <div className='aoiConfigPopup'
            style={{ left: `${promptPosition.x}px`, top: `${promptPosition.y}px` }}
        >
            <div className='config-header-section'>
                <div className='config-actions'>
                    {Object.keys(editAreaOfInterestObject).length === 0 && <button
                        className="config-actions-btn"
                        onClick={() =>
                            closeFunction()
                        }
                    >
                        <CloseSvg />
                    </button>}

                    <button
                        className="config-actions-btn"
                        onClick={() => saveFunction()}
                    >
                        <SaveSvg />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AoiConfigPopup;